import { createApp,getCurrentInstance } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from "./request/http";  //封装axios
import 'amfe-flexible';
import Vant from 'vant' //全局引用element Plus
import '../node_modules/vant/lib/index.css'
// import VConsole from 'vconsole';
import axios from 'axios';
import ElementPlus from 'element-plus' //全局引用element Plus
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ElIconModules from '@element-plus/icons';//导入所有element icon图标
// const vConsole = new VConsole();
const app = createApp(App)
app.use(store).use(router).use(http).use(Vant).use(ElementPlus, { size: 'default', locale: zhCn,});
app.mount("#app");

let refreshTimer = setInterval(()=>{
	axios.put('/app/renewToken',{},{headers: {'Authorization':localStorage.getItem('token')}}); 
}, 60000); 