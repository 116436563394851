import axios from 'axios';
import { showNotify  } from 'vant';
import $router from '@/router'
let tokenVal: any = null;  //定义token的值
let tokenManagerVal:any = null;
checkToken();
function checkToken() {	//获取token的值	

	if (localStorage.getItem('token') == undefined) {
		//获取UE的token
		if (localStorage.getItem('UEtoken') == undefined) {
			tokenVal = "";
		} else {
			tokenVal = localStorage.getItem('UEtoken')
		}
	} else {
		// console.log(localStorage.getItem('token'));
		if (localStorage.getItem('token') == null) {
			tokenVal = "";
		} else {
			tokenVal = localStorage.getItem('token');
		}
	}
}

const errorBack = () => {
	$router.push("/PersonalCenter")
}

// 创建一个 axios 实例
const service = axios.create({
	baseURL: '', // 所有的请求地址前缀部分
	timeout: 60000, // 请求超时时间毫秒
	withCredentials: true, // 异步请求携带cookie
	headers: {
		// 设置后端需要的传参类型
		'X-Requested-With': 'XMLHttpRequest',
		'menuUri': window.location.pathname
	},
})

let loadingInstance: any = null;
localStorage.setItem('showLoad', '1')
// 判断是否需要刷新
let totalTime: number = 15 * 60;
if (localStorage.getItem('updataToken') == '1') {
	let clock = setInterval(() => {
		totalTime--;
		if (totalTime == 0) {
			localStorage.setItem(('showLoad'), '0')
			totalTime = 15 * 60;
			//当倒计时小于0时清除定时器
			clearInterval(clock); //关闭
			$put("/ops/renewToken", {}).then((res: any) => {
				if (res.code == 200) {
					localStorage.setItem('updataToken', '1')
					localStorage.setItem(('showLoad'), '1')
				}
			})
		}
	}, 1000);
}

// 添加请求拦截器
service.interceptors.request.use(
	function (config: any) {
		console.log(config);
		// 在发送请求之前做些什么
		config.headers.menuUri = window.location.pathname
		if (localStorage.getItem('showLoad') !== '0') {
			// loadingInstance = ElLoading.service({ fullscreen: true }) //加载转圈
		}
		checkToken()
		if (tokenVal !== null && tokenVal !== '') {
			if(!config.headers.Authorization){
				config.headers.Authorization = tokenVal;	
			}	
		}
		return config
	},
	function (error) {
		// 对请求错误做些什么
		// console.log(error)
		return Promise.reject(error)
	}
)

// 添加响应拦截器
service.interceptors.response.use(
	function (response) {
		if (loadingInstance !== null) {
			loadingInstance.close()
		}
		// 2xx 范围内的状态码都会触发该函数。
		// dataAxios 是 axios 返回数据中的 data
		const dataAxios = response.data
		const code = dataAxios.reset
		checkToken()
		return dataAxios
	},
	function (error) {
		if (loadingInstance !== null) {
			loadingInstance.close()
		}

		//超时处理
		if (error.config) {
			showNotify({
				type: 'danger',
				message: '连接超时1',
				duration:1000,
				onClose:()=>{
						return Promise.reject(error)
				}
			});
		}

		// 超出 2xx 范围的状态码都会触发该函数。
		// 对响应错误做点什么
		if (error.response.status == 400) {
			showNotify({
				type: 'danger',
				message:  error.response.data.message,
				duration:2000,
			});
		} else if (error.response.status == 401) {
			localStorage.removeItem('token');
			errorBack();
			// showNotify({
			// 	type: 'danger',
			// 	message: '无效/过期的会话，请重新登录',
			// 	duration:2000,
			// 	onClose:()=>{
			// 		errorBack()
			// 	}
			// });
		} else if (error.response.status == 404) {
			showNotify({
				type: 'danger',
				message: '网络连接失败，请检查网络或联系管理员！',
				duration:2000
			});
		} else if (error.response.status == 405) {
			showNotify({
				type: 'danger',
				message: '网络连接失败，请检查网络或联系管理员！',
				duration:2000
			});
		} else if (error.response.status == 500) {
			showNotify({
				type: 'danger',
				message: '服务连接失败，请联系管理员!',
				duration:2000
			});
		}

		return Promise.reject(error)
	}
)


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $get = (url: string, params: any,headers:any={}) => {
	return new Promise((resolve, reject) => {
		service.get(url, {
			params: params,
			headers:headers,
		}).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err.data)
		})
	})
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $post = (url: string, params: any,headers:any={}) => {
	return new Promise((resolve, reject) => {
		service.post(url, params,{headers:headers})
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err.data)
			})
	})
}

/**
 * put
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $put = (url: string, params: any,headers:any={}) => {
	return new Promise((resolve, reject) => {
		service.put(url, params,{headers:headers})
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err.data)
			})
	})
}

/**
 * delete
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $delete = (url: string, params: any) => {
	return new Promise((resolve, reject) => {
		service.delete(url, params)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err.data)
			})
	})
}

export default {
	install: (app: any) => {
		app.config.globalProperties['$get'] = $get;
		app.config.globalProperties['$post'] = $post;
		app.config.globalProperties['$put'] = $put;
		app.config.globalProperties['$delete'] = $delete;
	}
}
