<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
  </router-view>
</template>
<style lang="less">
html,
body,
#app {
  padding: 0px !important;
  margin: 0px !important;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  cursor: default;
  background: #FFFFFF;
  font-size: 12px;
}

.tox-tinymce-aux {
  z-index: 9999 !important;
}

* {
  box-sizing: border-box;
}
</style>