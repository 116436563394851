import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    meta: {isAuth:true, title: '' },
    component: () => import('@/views/Index.vue'),
  },
  {
    path: '/GetTokenByGTY',
    name: 'GetTokenByGTY',
    meta: {title: '获取token' },
    component: () => import('@/views/GetTokenByGTY.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    meta: {isAuth:true, title: '' },
    component: () => import('@/views/Login.vue')
  },
  {
    path:'/LearnContent',
    name:'LearnContent',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/LearnContent.vue')
  },
  {
    path:'/LearnResult',
    name:'LearnResult',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/LearnResult.vue')
  },
  {
    path:'/LearnModel',
    name:'LearnModel',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/LearnModel.vue')
  },
  {
    path:'/LearnVideo',
    name:'LearnVideo',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/LearnVideo.vue')
  },
  {
    path:'/LearnFile',
    name:'LearnFile',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/LearnFile.vue')
  },
  {
    path:'/Exam',
    name:'Exam',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/Exam.vue')
  },
  {
    path:'/ExamResult',
    name:'ExamResult',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/ExamResult.vue')
  },
  {
    path:'/MistakeCollection',
    name:'MistakeCollection',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/MistakeCollection.vue')
  },
  {
    path:'/Archives',
    name:'Archives',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/Archives.vue')
  },
  {
    path:'/PersonalCenter',
    name:'PersonalCenter',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/PersonalCenter.vue')
  },
  {
    path:'/PersonalInfo',
    name:'PersonalInfo',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/PersonalInfo.vue')
  },
  {
    path:'/PersonalInfoShow',
    name:'PersonalInfoShow',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/PersonalInfoShow.vue')
  },
  {
    path:'/SelfLearn',
    name:'SelfLearn',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/SelfLearn.vue')
  },
  {
    path:'/TrainPlan',
    name:'TrainPlan',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/manage/TrainPlan.vue')
  },
  {
    path:'/OnePerson',
    name:'OnePerson',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/manage/OnePerson.vue')
  },
  {
    path:'/TrainFile',
    name:'TrainFile',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/manage/TrainFile.vue')
  },
  {
    path:'/EmployeeInfo',
    name:'EmployeeInfo',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/manage/EmployeeInfo.vue')
  },
  {
    path:'/TrainRecordIn',
    name:'TrainRecordIn',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/manage/TrainRecordIn.vue')
  },
  {
    path:'/TrainRecordOut',
    name:'TrainRecordOut',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/manage/TrainRecordOut.vue')
  },
  {
    path:'/TrainRecordEdit',
    name:'TrainRecordEdit',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/manage/TrainRecordEdit.vue')
  },
  {
    path:'/Participant',
    name:'Participant',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/manage/Participant.vue')
  },
  {
    path:'/ExamState',
    name:'ExamState',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/manage/ExamState.vue')
  },
  {
    path:'/Annex',
    name:'Annex',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/manage/Annex.vue')
  },
  {
    path:'/ManagerLogin',
    name:'ManagerLogin',
    meta:{isAuth:true,title:''},
    component:() => import('@/views/manage/ManagerLogin.vue')
  },
  /**
   * 路由重定向
   */
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to:any, from:any, next:any) => {

  // if(to.query.code){
  //   localStorage.setItem('code',to.query.code);
  // }  
 
  // localStorage.setItem('token','eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7ImNoZWNrU3RyIjoiY2YwODhhMTctZDUzNi00ZjA3LWIyNmMtMDJlN2U4YTRjNmIzIiwibmlja05hbWUiOiJ0ZXN0QWRtaW4iLCJpcCI6IjEwLjguMS4zMyIsImluZGVwZW5kZW5jZSI6ZmFsc2UsInVzZXJOYW1lIjoidGVzdEFkbWluIiwidXNlcklkIjoiZTdmMDFhZTEtOTIyOS00MmYyLWI4MWUtOTZhOTEyNmQwNWMxIn0sImV4cCI6MTc5Nzg0MTA1MiwiaWF0IjoxNzExNDQxMDUyfQ.4sSNyV5XNM3XP2NYmtyiGZcXrcOLCwpoHWkWBuuDAbM');

  let token = localStorage.getItem('token')  //读取token
  let expire = localStorage.getItem('token_expire');
  let nowTime = Math.ceil(Date.now()/1000); 

  if (to.meta.isAuth) { //判断是否需要鉴权
    if (token === null || token == 'undefined') {

      axios.post('/app/login',{code:to.query.code,type:'wechat'}).then((res)=>{
        // localStorage.setItem('code',getCode);
        localStorage.setItem('token',res.data.data.token);
        localStorage.setItem('token_expire', JSON.stringify(nowTime+3600));
        // 如果访问非绑定页，校验是否绑定，没有的话去绑定
        if(to.path == '/Login'){
          next();
        }else{
          axios.get('/app/user/check',{headers: {'Authorization':res.data.data.token}}).then((re)=>{
            if(re.data.data  || localStorage.getItem('forJLZYPT')){
              next();
            }else{
            next({name:'Login'})  
            }
          })           
        }

      })
    }else if(expire === null || nowTime > parseInt(expire)){
        if(to.query.code){
            axios.post('/app/login',{code:to.query.code,type:'wechat'}).then((res)=>{
            // localStorage.setItem('code',getCode);
            localStorage.setItem('token',res.data.data.token);
            localStorage.setItem('token_expire', JSON.stringify(nowTime+3600));
            // 如果访问非绑定页，校验是否绑定，没有的话去绑定
            if(to.path == '/Login'){
              next();
            }else{
              axios.get('/app/user/check',{headers: {'Authorization':res.data.data.token}}).then((re)=>{
                if(re.data.data || localStorage.getItem('forJLZYPT')){
                  next();
                }else{
                next({name:'Login'})  
                }
              })           
            }

          })
        }else{
          axios.put('/app/renewToken',{},{headers: {'Authorization':localStorage.getItem('token')}}).then((res)=>{
            if(res.data.code == 200){
             localStorage.setItem('token_expire', JSON.stringify(nowTime+3600));
             next();
            }
          })           
        }

    }else{
      // 如果访问非绑定页，校验是否绑定，没有的话去绑定
      if(to.path == '/Login'){
        next();
      }else{
        axios.get('/app/user/check',{headers: {'Authorization':token}}).then((re)=>{
          if(re.data.data || localStorage.getItem('forJLZYPT')){
            next();
          }else{
          next({name:'Login'})  
          }
        })   
      }

      document.title = to.meta.title as string
      // next()
    }
  } else {
    document.title = to.meta.title as string
    next()
  }
})

export default router;
