import { createStore } from "vuex";
import person from "./person";

//  如果您不打算开发大型单页应用，使用 Vuex 可能是繁琐冗余的。
//  很多应用都用不到Vuex，如无必要，勿增实体 ,当你无法很好进行数据管理的时候，你才需要用Vuex

export default createStore({
  modules: {   //子模块
    personData: person
  }
});